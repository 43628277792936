import { MessageCircle, Send } from 'lucide-react';
import React from 'react';
import { Link, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import CountryCodeSelect from './components/CountryCodeSelect';
import MessageInput from './components/MessageInput';
import PhoneNumberInput from './components/PhoneNumberInput';
import useWhatsAppSender from './hooks/useMessageSender';

const phoneNumberdam = '919789411663';
// Check if the user is on a mobile device
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Set the appropriate WhatsApp link based on the platform
const whatsappLink = isMobile
  ? `whatsapp://send/?phone=${phoneNumberdam}`
  : `https://web.whatsapp.com/send?phone=${phoneNumberdam}`;

// Telegram link (modify with the correct Telegram number or username)
const telegramLink = `tg://msg?text=Hello&to=+1555999`; // Replace with actual Telegram number or username

// SMS link
const smsLink = `sms:1234?body=hi`; // Replace 1234 with the desired phone number

// Informational pages
const About = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold">About Us</h2>
    <p className="mt-6">

      <h3 className="text-xl text-black-500 font-bold">Welcome to Insta-Chat!
      </h3>Insta-Chat is a simple, convenient tool that allows you to send messages on WhatsApp, Telegram, and via SMS without the need to save phone numbers in your contacts. Our mission is to make communication easier, faster, and more efficient while respecting your privacy.

      <p className="text-xl text-black-500 font-bold mt-6"> What We Do</p>
      Provide a seamless way to send messages without cluttering your contact list.<br></br>
      Ensure your data remains private and secure.

      <p className="text-xl text-black-500 font-bold mt-6"> Why Choose Us?</p>
      Privacy First: We never store your numbers or messages.<br></br>
      No Hassle    : No sign-ups, no accounts – just quick and easy messaging.<br></br>
      User-Friendly: A straightforward platform designed for everyone.<br></br>

      <p className="text-xl text-black-500 font-bold mt-6"> Our Goal</p>
      To simplify the way you communicate without compromising on your privacy or security. Whether it’s a one-time message or regular communication, Insta Chat is here to help.
      <br></br>
      Thank you for choosing Insta-Chat. Let’s keep things simple!
    </p>
  </div>
);

const PrivacyPolicy = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold">Privacy Policy</h2>
    <p className="mt-4">
      Your privacy matters to us at Insta-Chat.<br></br> Here's how we handle your data when you use our website to send messages via WhatsApp, Telegram, or SMS without saving numbers in your contacts.

      <p className="text-xl text-black-500 font-bold mt-4">  What We Don’t Collect</p>
      No Contact Info Saved: We don’t save the numbers you input.<br></br>
      No Messages Stored: We don’t keep the messages you send.<br></br>
      No Accounts Needed:You can use Insta-Chat without signing up or sharing personal details.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4">  How It Works</p>
      Insta-Chat helps you send messages through WhatsApp, Telegram, or SMS.<br></br>
      Numbers and messages go directly to these platforms and aren’t stored on our servers.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4"> Third-Party Services</p>
      Your interactions are governed by the privacy policies of WhatsApp, Telegram, or your SMS provider.<br></br>
      Insta-Chat isn’t responsible for how these platforms handle your data.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4">No Tracking</p>
      No Cookies: We don’t use cookies or tracking tools.<br></br>
      No Analytics: We don’t collect data about your activities.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4">Security</p>
      We use SSL encryption to protect your data during use, even though we don’t store or process it.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4"> For Kids</p>
      Insta-Chat is not for children under 13, and we don’t knowingly collect any data from them.<br></br>

      <p className="text-xl text-black-500 font-bold mt-4"> Policy Updates</p>
      We may update this policy as needed. Any changes will be posted here with the latest update date.<br></br>
    </p>
  </div>
);

// Contact Us component remains the same
const ContactUs = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold">Contact Us</h2>
    <p className="mt-4 flex items-center space-x-2">
      For any questions or feedback, please
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-green-600 hover:underline flex items-center space-x-1 mx-2"
      >
        <MessageCircle size={15} aria-label="WhatsApp Icon" />
        <span>WhatsApp us</span>
      </a>
      or
      <a
        href={telegramLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:underline flex items-center space-x-1 mx-2"
      >
        <MessageCircle size={15} aria-label="Telegram Icon" />
        <span>Message us on Telegram</span>
      </a>
      or
      <a
        href={smsLink}
        className="text-gray-600 hover:underline flex items-center space-x-1 mx-2"
      >
        <MessageCircle size={15} aria-label="SMS Icon" />
        <span>Send an SMS</span>
      </a>.
    </p>
  </div>
);

const App = () => {
  const {
    countryCode,
    phoneNumber,
    message,
    error,
    setCountryCode,
    setPhoneNumber,
    setMessage,
    sendWhatsAppMessage,
    sendTelegramMessage,
    sendSmsMessage,
  } = useWhatsAppSender();

  const location = useLocation(); // Use useLocation to get the current path
  const isHomePage = location.pathname === "/"; // Check if the current path is "/"

  return (
    <div className={`min-h-screen flex flex-col ${isHomePage ? "bg-gray-100" : "bg-gray-100"}`}>

      {/* Header */}
      <header className=" text-white p-4" style={{ backgroundColor: "#5e1c57" }}>
        <nav className="flex justify-end items-center">
          {/* <Link to="/" className="text-xl font-bold hover:underline">
            Insta-Chat
          </Link> */}
          <div className="space-x-4 items-center">
            <Link to="/" className="hover:underline">Home</Link>
            <Link to="/about" className="hover:underline">About</Link>
            <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link>
            {/* Hide the "Contact Us" link */}
          </div>
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-grow p-4">
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-full max-w-md mx-auto bg-white shadow-lg rounded-2xl overflow-hidden">
                {/* Header Section */}
                <div className=" text-white p-6 flex items-center justify-between" style={{ backgroundColor: "#5e1c57" }} >
                  <div>
                    <h1 className="text-2xl font-bold">Insta-Chat</h1>
                    <p className="text-md pt-1">Send messages instantly</p>
                  </div>
                  <MessageCircle size={40} className="text-white" aria-label="Message Icon" />
                </div>

                {/* Form Section */}
                <div className="p-6 space-y-4">
                  <CountryCodeSelect value={countryCode} onChange={setCountryCode} phoneInput={setPhoneNumber} />
                  <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} countryCode={countryCode} />
                  <MessageInput value={message} onChange={setMessage} />

                  {/* Error Message */}
                  {error && (
                    <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-2 rounded-lg text-sm">
                      {error}
                    </div>
                  )}

                  {/* Send Button */}
                  <button
                    onClick={sendWhatsAppMessage}
                    className="w-full bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition duration-300 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg"
                    aria-label="Send WhatsApp Message"
                  >
                    <Send size={20} />
                    <span>Send WhatsApp</span>
                  </button>

                  <button
                    onClick={sendTelegramMessage}
                    className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg"
                    aria-label="Send WhatsApp Message"
                  >
                    <Send size={20} />
                    <span>Send Telegram</span>
                  </button>
                  {/* Conditional SMS Link (only for mobile devices) */}
                  {isMobile && (
                    <button
                      onClick={sendSmsMessage}
                      className="w-full bg-slate-500 text-white py-3 rounded-lg hover:bg-slate-600 transition duration-300 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg"
                      aria-label="Send WhatsApp Message"
                    >
                      <Send size={20} />
                      <span>Send SMS</span>
                    </button>
                  )}

                </div>
              </div>
            }
          />

          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </main>

      {isHomePage && (

        <div className="p-6 text-center">
          <h2 className="text-2xl font-bold">Insta Chat</h2>
          <p className="mt-4">
            Insta Chat is a fast, convenient, and privacy-focused messaging tool that simplifies how you communicate on WhatsApp.
            <br></br>With Insta Chat, you can send messages to any WhatsApp number without the need to save the contact on your phone.
            <br></br>This eliminates unnecessary clutter in your contacts and saves time, making it ideal for casual chats, business inquiries,
            or one-time communications.
            <p className="text-xl text-black-500 font-bold mt-4">  Key Features:</p>
            <strong>Quick Messaging:</strong> Send WhatsApp messages instantly by entering the recipient’s phone number.<br></br>
            <strong>No Contact Saving Needed:</strong> Avoid cluttering your phonebook with temporary or one-time contacts.<br></br>
            <strong>User-Friendly Interface:</strong> Designed for simplicity and speed, making it easy for anyone to use.<br></br>
            <strong> Cross-Device Compatibility:</strong> Works seamlessly on mobile and desktop devices.<br></br>
          </p>
        </div>

      )}

      {/* Footer */}
      <footer className="text-center text-white p-4" style={{ backgroundColor: "#5e1c57" }}>
        © {new Date().getFullYear()} Insta-Chat. All rights reserved.
        <br />

        <div className="">
          We prioritize user privacy with a transparent, secure service. Our platform uses only standard web server logging for debugging, without collecting additional data or using cookies. Phone numbers are routed directly to WhatsApp servers and never stored on our system.
          This service remains completely independent of Meta, Facebook, Telegram , SMS , and WhatsApp, ensuring a private and direct communication experience.
        </div>
      </footer>

    </div>
  );
};

const RootApp = () => (
  <Router>
    <App />
  </Router>
);

export default RootApp;
